import React from 'react';

import {
    PageAbout,
    PageContactForm,
    PageCounter,
    PageCursor,
    PageFooter,
    PageGameStart,
    PageHeader,
    PagePreorder,
    PageProductDetail,
    PagePromoArea,
    PageRoadmap,
    PageSlider,
    PageSponsor,
    PageTeamStart
} from "../components";


export default function HomeScreen() {
    return <>
        <PageCursor />
        <PageHeader />
        <PageSlider />
        <PageAbout />
        <PageCounter />
        <PageProductDetail />
        <PageGameStart />
        <PagePromoArea />
        <PageTeamStart />
        <PagePreorder />
        <PageRoadmap />
        <PageSponsor />
        <PageContactForm />
        <PageFooter />
    </>;
}