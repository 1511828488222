import React from 'react';


export default function _() {
    return <>
        {/* <!-- Counter Area Start --> */}
        <section className="fag-counter-area section_100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="counter-box">
                            <h4>In-game Marketplace</h4>
                            <p>
                                All Senshi NFTs are digitally owned, transferrable, sold in-game. Buy or Sell your NFTs collected in the game on the marketplace to get profits.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="counter-box">
                            <h4 >PVE</h4>
                            <p>
                                Use your Senshi NFT assets for fighting in PVE to receive rewards of SENSHI tokens and experience for levelling.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="counter-box">
                            <h4>Staking/Farming</h4>
                            <p>
                                Earn more SENSHI Tokens with your Tokens, increasing asset value for holders and creating significant value for the ecosystem.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="counter-box">
                            {/* <!-- <h3 className="heading_animation"><span className="0"></span></h3> --> */}
                            <h4 className="heading_animation"><span>Championship</span></h4>
                            <p>
                                Senshi NFT Owners can choose to participate, and if they can achieve a high rank on the leaderboard, they will be rewarded with SENSHI Token.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Counter Area End --> */}
    </>;
}