import React from 'react';


export default function _() {
    return <>
        {/* <!-- Team Area Start --> */}
        <section className="fag-team-area section_100" id="team">
            <div className="top-layer"></div>
            <div className="bottom-layer"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="site-heading">
                            <h2 className="heading_animation">SENSHI <span>CLAN</span></h2>
                            <p>GREAT GAMES ARE BUILT BY GREAT TEAMS</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/danny.jpg" alt="team-1" />
                            </div>
                            <div className="team-details">
                                <h3>Danny</h3>
                                <span>Chief Game Developer</span>
                                <div className="team-social">
                                    <ul>
                                        <li><a href="https://twitter.com/the_aratan"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/dancharlesokoto"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="https://t.me/the_aratan"><i className="fa fa-telegram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/dami.jpeg" alt="team-2" />
                            </div>
                            <div className="team-details">
                                <h3>Dami</h3>
                                <span>Chief Blockchain Deve.</span>
                                <div className="team-social">
                                    <ul>
                                        <li><a href="https://twitter.com/GeniusDephx"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/adedamola-ephraim"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/halima.jpg" alt="team-3" />
                            </div>
                            <div className="team-details">
                                <h3>Halima</h3>
                                <span>Content Developer</span>
                                <div className="team-social">
                                    <ul>
                                        <li><a href="https://twitter.com/the_aratan"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/halima-abubakar-403873154/"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/sidd.jpeg" alt="team-4" />
                            </div>
                            <div className="team-details">
                                <h3>Sidd</h3>
                                <span>Operations and Legal</span>
                                <div className="team-social">
                                    <ul>
                                        <li><a href="https://www.linkedin.com/in/siddhant-pandey-1346b9171"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="https://t.me/isitlrgalsid"><i className="fa fa-telegram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/israel.jpeg" alt="team-1" />
                            </div>
                            <div className="team-details">
                                <h3>Israel</h3>
                                <span>Frontend Developer</span>
                                <div className="team-social">
                                    <ul>
                                        <li><a href="https://twitter.com/Akinwumidi"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="https://linkedin.com/in/Akinwumidi"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/sam.jpeg" alt="team-1" />
                            </div>
                            <div className="team-details">
                                <h3>Sam</h3>
                                <span>Creative Designer</span>
                                <div className="team-social">
                                    <ul>

                                        <li><a href="https://www.linkedin.com/in/SamOge"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/rotimi.png" alt="team-1" />
                            </div>
                            <div className="team-details">
                                <h3>Rotimi</h3>
                                <span>Product Designer </span>
                                <div className="team-social">
                                    <ul>

                                        <li><a href="https://linkedin.com/in/solomon-rotimi"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/promise.jpeg" alt="team-1" />
                            </div>
                            <div className="team-details">
                                <h3>Promise</h3>
                                <span>Backend Developer</span>
                                <div className="team-social">
                                    <ul>

                                        <li><a href="https://www.linkedin.com/in/promiseoghenevwefe"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-item">
                            <div className="team-image">
                                <img src="assets/img/dan.jpeg" alt="team-1" />
                            </div>
                            <div className="team-details">
                                <h3>Dan</h3>
                                <span>Community Manager</span>
                                <div className="team-social">
                                    <ul>

                                        <li><a href="www.linkedin.com/in/daniel-osaze-757903120"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Team Area End --> */}
    </>
}