import React from 'react';

export default function _() {
    return <>
        {/* <!-- About Area Start --> */}
        <section className="fag-video-area section_100">
            <div className="top-layer"></div>
            <div className="bottom-layer"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-top">
                            <h2><span>In-Game</span> Experience</h2>
                            <p>SENSHI is an online NFT-based Play-to-Earn (P2E) Metaverse game built on the Unity Engine and powered by blockchain and player ownership! SENSHI is designed by a top-notch gaming studio filled with industry-leading game design experts.
                                <br />
                                Senshi players will be immersed in intense gameplay, environments filled with quality graphics and sound effects. Players will have the opportunity to battle through different game stages to Emerge THE SENSHI OF REALMS while earning HUGE rewards!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="fag-video-inn">
                            <img className="zooming" src="assets/img/game.jpg" alt="theater thumb" />
                            <a className="play-video" href="https://www.youtube.com/watch?v=-i6ArgzwDjY">
                                <i className="fa fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- About Area End --> */}
    </>
}