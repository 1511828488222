import React from 'react';
import { Link } from 'react-router-dom';


export default function _() {
    return <>
        <nav className="fag-header navbar navbar-expand-lg">
            <div className="container">
                <a className="navbar-brand" href="index.html">
                    <img src="assets/img/logo.png" alt="site logo" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="menu-toggle"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="header_menu  mr-auto">
                        <li className="nav-item active">
                            <Link to={'/'} className="nav-link">Senshi</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">Docs</a>
                            <ul className="dropdown-menu">
                                <li><a href="https://senshi-game.gitbook.io/senshi-ecosystem-v1/senshi-ecosystem" target={'_blank'}>Why SENSHI</a></li>
                                <li><a href="https://senshi-game.gitbook.io/senshi-ecosystem-v1/" target={'_blank'}>Whitepaper</a></li>
                                <li><a href="https://senshi-game.gitbook.io/senshi-ecosystem-v1/tokenomics" target={'_blank'}>Tokenomics</a></li>
                                <li><a href="https://senshi-game.gitbook.io/senshi-ecosystem-v1/roadmap" target={'_blank'}>Roadmap</a></li>

                            </ul>
                        </li>
                        <li className="nav-item ">
                            <a href="#nfts" className="nav-link">NFTS</a>
                        </li>
                        <li className="nav-item">
                            <a href="#team" className="nav-link">TEAM</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className="nav-link">Contact</a>
                        </li>
                    </ul>
                    <div className="header-right  my-2 my-lg-0">
                        <div className="header-search">
                            <a href="#" id="search-trigger"><i className="fa fa-search"></i></a>
                        </div>
                        {/* <!-- Search Block --> */}
                        <div className="search-block">
                            <a href="#" className="search-toggle">
                                <i className="fa fa-times"></i>
                            </a>
                            <form onSubmit={ev => alert('This feature is not ready yet!')}>
                                <input type="text" name="search" placeholder="What're you looking for?" />
                                <span className="fa fa-search"></span>
                            </form>

                        </div>
                        {/* <!-- /Search Block --> */}

                        <div className="header-lang nav-item dropdown">
                            <a className="lang-btn nav-link" target={'_blank'} href="http://stake.senshigame.com" role="button"><img src="assets/img/stake.png" alt="stake" />Stake Coin</a>
                        </div>

                        {/* <div className="header-auth  nav-item ">
                            <Link to={'/register'} className="lang-btn nav-link"><img src="assets/img/admin.png" alt="admin" />
                                Play Game - Soon
                            </Link>
                        </div> */}

                        <div className="team-social nav-item">
                            <ul>
                                <li><a href="https://twitter.com/senshigame"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://t.me/senshigame"><i className="fa fa-telegram"></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UC0xgMoJ6Gy8ltmJzQXz5__w"><i className="fa fa-youtube"></i></a></li>
                                <li><a href="https://medium.com/@senshirealms"><span className="fa fa-medium"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>;
}