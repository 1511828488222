import React from 'react';

export default function _() {
    return <>
        {/* <!-- Promo Area Start --> */}
        <section className="fag-promo-area">
            <div className="promo-iamge">
                <img src="assets/img/Fantasy_5_1920x1080.jpg" alt="promo" />
            </div>
            <div className="promo-info">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="promo-box">
                                <h3>Get Ready to fight</h3>
                                <p>To encourage a competitive environment within the SENSHI Community, we will introduce weekly and monthly online tournaments with massive rewards. Players will be able to earn a significant share of the total participation Reward Pool.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Promo Area End --> */}
    </>
}