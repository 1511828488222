import React from "react";
import { Link } from "react-router-dom";

export default function _() {
    return <>
        {/* <!-- Footer Area Start --> */}
        <footer className="fag-footer">

            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-bottom-inn">
                                <div className="footer-logo">
                                    <Link to={'/'} className="nav-link">
                                        <img src="assets/img/logo.png" alt="site logo" />
                                    </Link>
                                </div>
                                <div className="footer-social">
                                    <ul>
                                        <li><a href="https://twitter.com/senshigame"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="https://t.me/senshigame"><i className="fa fa-telegram"></i></a></li>
                                        <li><a href="https://www.youtube.com/channel/UC0xgMoJ6Gy8ltmJzQXz5__w"><i className="fa fa-youtube"></i></a></li>
                                        <li><a href="https://medium.com/@senshirealms"><span className="fa fa-medium"></span></a></li>
                                    </ul>
                                </div>
                                <div className="copyright">
                                    <p>&copy; Copyrights 2022 SENSHI - All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <!-- Footer Area End --> */}
    </>
}