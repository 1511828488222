import React from "react";
import { Link } from "react-router-dom";


export default function _() {
    return <>
        {/* <!-- Preorder Area Start --> */}
        <section className="fag-preorder-area">
            <div className="preorder-inn">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="preorder-box">
                                <h3><span>Who would be SENSEI?</span> MASTER OF THE REALMS</h3>
                                <Link to={'/register'} className="fag-btn-outline">Get Notified</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Preorder Area End --> */}
    </>
}