

import React from 'react';


export default function _() {
    return <>
        {/* <!-- Slider Area Start --> */}
        <section className="slider-area">
            <div className="fag-slide owl-carousel">
                <div className="fag-main-slide slide-1">
                    <div className="fag-main-caption">
                        <div className="fag-caption-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="slider-text">
                                            <h3>Anime themed PvE Metaverse battles.</h3>
                                            <h2>FIGHT</h2>
                                            <a className="popup-youtube fag-btn-outline" href="https://www.youtube.com/watch?v=btkkWOnjHnY"><span className="fa fa-play"></span>Watch Trailer</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fag-main-slide slide-2">
                    <div className="fag-main-caption">
                        <div className="fag-caption-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="slider-text">
                                            <h3>Receive tokens, trade NFTs & earn BIG!</h3>
                                            <h2>WIN & EARN</h2>
                                            <a className="popup-youtube fag-btn-outline" href="https://www.youtube.com/watch?v=btkkWOnjHnY"><span className="fa fa-play"></span>Watch Trailer</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fag-main-slide slide-3">
                    <div className="fag-main-caption">
                        <div className="fag-caption-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="slider-text">
                                            <h3>Dare to earn the title:</h3>
                                            <h2>MASTER OF THE REALMS</h2>
                                            <a className="popup-youtube fag-btn-outline" href="https://www.youtube.com/watch?v=btkkWOnjHnY"><span className="fa fa-play"></span>Watch Trailer</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Slider Area End --> */}
    </>
}