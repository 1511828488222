import React from "react";

export default function _() {
    return <>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="page-404__wrap">
                        <div className="login-wrapper">
                            <img className="login_user" src="assets/img/logo.png" alt="register user" />
                            <h3>Be Notified</h3>
                            <form>

                                <div className="form-row">
                                    <input type="email" placeholder="Email Address" />
                                </div>
                                <div className="form-row">
                                    <div className="custom-checkbox">
                                        <input id="terms" type="checkbox" name="terms" checked />
                                        <label>I agree to the <a href="#">Privacy Policy</a></label>
                                        <span className="checkbox"></span>
                                    </div>
                                </div>
                                <div className="form-row"></div>
                                <div className="form-row">
                                    <button className="fag-btn" type="submit">SEND!</button>
                                </div>
                            </form>
                            <div className="socials-wrapper">
                                <p>Follow us on Social Media</p>
                                <ul>
                                    <li><a href="https://www.youtube.com/channel/UC0xgMoJ6Gy8ltmJzQXz5__w" className="youtube"><i className="fa fa-youtube"></i></a></li>
                                    <li><a href="https://twitter.com/SenshiGame" className="twitter"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://t.me/SenshiGame" className="twitch"><i className="fa fa-telegram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}