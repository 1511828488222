

import React from "react";
import { PageCursor, RegisterForm } from "../components";

export default function _() {
    return <>
        <PageCursor />
        <div className="page-404  section--full-bg">
            <RegisterForm />
        </div>
    </>
}