import React from 'react';

export default function _() {
    return <>
        <section className="fag-testimonial-area section_100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="site-heading">
                            <h2 className="heading_animation">Road<span>map</span></h2>
                            <p>Senshi has been under development since Q3 2021. It was intended to release as a 2D Game for Web and Android. In early 2022, the team decided to bring SENSHI into the blockchain as a P2E Metaverse game.</p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="testimonial-slider owl-carousel">
                        <div className="single-testimonial">
                            <div className="testimonial-meta">

                                <div className="testimonial-title">
                                    <h3>Phase 1</h3>

                                </div>
                            </div>
                            <div className="testimonial-desc">

                                <p>
                                    Strategic Partnerships <br />
                                    Marketing <br />Seed and Private Sale <br />Alpha Game Release
                                </p>
                            </div>
                        </div>
                        <div className="single-testimonial">
                            <div className="testimonial-meta">
                                <div className="testimonial-title">
                                    <h3>Phase 2</h3>
                                </div>
                            </div>
                            <div className="testimonial-desc">
                                <p>IDO Public Sale <br />
                                    Exchange Listings <br />
                                    Farming/Staking <br />
                                    Beta Version Launch
                                </p>
                            </div>
                        </div>

                        <div className="single-testimonial">
                            <div className="testimonial-meta">

                                <div className="testimonial-title">
                                    <h3>Phase 3</h3>

                                </div>
                            </div>
                            <div className="testimonial-desc">
                                <p> NFT Marketplace Release <br />
                                    Game NFT Sale <br />
                                    Full Game Release <br />
                                    Africa Gaming Competition
                                </p>
                            </div>
                        </div>
                        <div className="single-testimonial">
                            <div className="testimonial-meta">

                                <div className="testimonial-title">
                                    <h3>Phase 4</h3>

                                </div>
                            </div>
                            <div className="testimonial-desc">
                                <p>Game Update: New weapons, skins, attachments and maps <br />
                                    Senshi Wallet for Gaming <br />
                                    New Game Mode <br />
                                    Tournaments, Special events</p>
                            </div>
                        </div>

                        <div className="single-testimonial">
                            <div className="testimonial-meta">

                                <div className="testimonial-title">
                                    <h3>Phase 5</h3>

                                </div>
                            </div>
                            <div className="testimonial-desc">
                                <p>Tournaments, <br />
                                    Competitions, <br />
                                    Special Events <br /> Senshi Arcade</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
}