import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "../../screens/HomeScreen";
import RegisterScreen from "../../screens/RegisterScreen";


export default function _() {
    return (<>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="register" element={<RegisterScreen />} />
            </Routes>
        </BrowserRouter>
    </>);
}