import React from "react";

export default function _() {
    return <>
        {/* <!-- Contact Form Start --> */}
        <section className="fag-contact-form section_100" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="site-heading">
                            <h2 className="heading_animation">Send <span>Message</span></h2>
                            <p>You can send a mail to <span style={{ color: '#ff7a21' }}>team@senshigame.com</span> and we will get back to you within 48 hours.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact-form-inn">
                            <form>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="comment-field">
                                            <input className="ns-com-name" name="name" placeholder="Name" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="comment-field">
                                            <input className="ns-com-name" name="email" placeholder="Email" type="email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="comment-field">
                                            <textarea className="comment" placeholder="Write your message here..." name="comment"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="comment-field form-action">
                                            <button type="submit" className="fag-btn">Send Message </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Contact Form End --> */}
    </>
}