import React from "react";

export default function _() {
    return <>
        {/* <!-- Sponsor Area Start --> */}
        <section className="fag-sponsor-area section_100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="site-heading">
                            <h2 className="heading_animation">Meet Our <span>Partners</span></h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="sponsor-box-item">
                            <div className="row">
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://arata.io/">
                                        <img
                                            src="assets/img/arata.png"
                                            alt="sponsor1"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://www.adanianlabs.io/">
                                        <img src="assets/img/adan.png" alt="sponsor2"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://www.lbank.info/">
                                        <img src="assets/img/lbank.svg" alt="sponsor3"
                                            style={{ height: '15vh', width: '15vw' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://gempad.app/">
                                        <img src="assets/img/gempad.svg" alt="sponsor4"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://launchblock.com">
                                        <img src="assets/img/launchblock.png" alt="sponsor5"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://coinsult.net/projects/senshi/">
                                        <img src="assets/img/coinsult.png" alt="sponsor6"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://app.a11.finance/">
                                        <img src="assets/img/apollov.png" alt="sponsor7"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://unimaxpad.com/">
                                        <img src="assets/img/unimaxpad.png" alt="sponsor8"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://apeswap.finance/">
                                        <img src="assets/img/apeswaplogo.png" alt="sponsor9"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                                <div className="col-11 col-md-5 col-lg-2 each-sponsor">
                                    <a href="https://t.me/ElysiumDefi">
                                        <img src="assets/img/elsium.jpeg" alt="sponsor10"
                                            style={{ height: '15vh' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Sponsor Area End --> */}
    </>;
}