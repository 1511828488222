import React from 'react';

export default function _() {
    return <>
        <section className="fag-product-details section_100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="product-details-image">
                            <img src="assets/img/game-console-01.jpeg" alt="product" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="product-details-text">
                            <h3>The Blockchain Console Revolution</h3>

                            <div className="single-pro-page-para">
                                <p>As part of our ecosystem, we are building a multichain Gaming wallet for blockchain games. This would allow different Blockchain games to be listed in the wallet with ability for players to claim rewards from those play2earn games.</p>
                            </div>

                            <div className="single-pro-page-para">
                                <p>Utimately, we are building a console device that would change the way games are being played in the blockchain space. This device would become the host for several games playable on big screens with gamepads and connect multiple players globally to compete online.</p>
                            </div>

                            <div className="single-pro-page-para">
                                <p>For technical specifications for the prototype, do check the Whitepaper.</p>
                            </div>

                            <div className="single-shop-page-btn">
                                <a target='_blank' href="https://docs.senshigame.com/blockchain-console" className="fag-btn">Read More<span></span></a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>;
}