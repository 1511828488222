import React from 'react';


export default function _() {
    return <>
        {/* <!-- Games Area Strat --> */}
        <section className="fag-games-area section_140" id="nfts">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="site-heading">
                            <h2 className="heading_animation">IN-GAME <span>ASSETS</span></h2>
                            <p>The Official Launch will include 16 Characters and 12 Realms, each with distinct details.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="games-masonary">
                            <div className="projectFilter project-btn">
                                <ul>
                                    <li><a href="#" data-filter=".desktop">Realms</a></li>
                                    <li><a href="#" data-filter=".mobile" className="current">Characters</a></li>
                                </ul>
                            </div>
                            <div className="clearfix gamesContainer">
                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/p1.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Ash</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}
                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/p2.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Gandur</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}
                                <div className="games-item desktop">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/r2.PNG" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Baldur</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}
                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/p3.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Gilgamech</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}
                                <div className="games-item desktop">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/r4.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">SteamWorld</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}
                                <div className="games-item desktop">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/r3.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Puzzle of Flesh</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}
                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/p4.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Moto</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}
                                <div className="games-item desktop">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/r1.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Sacred</a></h3>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end game item --> */}

                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/Kitsune.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Kitsune</a></h3>

                                        </div>
                                    </div>
                                </div>

                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/Gagoze.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Gagoze</a></h3>

                                        </div>
                                    </div>
                                </div>

                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/Baku.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Baku</a></h3>

                                        </div>
                                    </div>
                                </div>

                                <div className="games-item mobile">
                                    <div className="games-single-item img-contain-isotope">
                                        <div className="games-thumb">
                                            <div className="games-thumb-image">
                                                <a href="#">
                                                    <img src="assets/img/Shinigami.png" alt="games" />
                                                </a>
                                            </div>
                                            <div className="game-overlay">

                                            </div>
                                        </div>
                                        <div className="games-desc">
                                            <h3><a href="#">Shinigami</a></h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Games Area End --> */}
    </>
}